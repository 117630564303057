var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "pt-2 px-1" }, [
        _c("div", { staticClass: "row rounded-lg overflow-hidden shadow" }, [
          _c("div", { staticClass: "col-5 px-0" }, [
            _c("div", { staticClass: "bg-white" }, [
              _c("div", { staticClass: "bg-gray px-4 py-2 bg-light" }, [
                _c("p", { staticClass: "h5 mb-0 py-1" }, [_vm._v("Recent")])
              ]),
              _c("div", { staticClass: "messages-box" }, [
                _c("div", { staticClass: "list-group rounded-0" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "list-group-item list-group-item-action active text-white rounded-0"
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          staticClass: "rounded-circle",
                          attrs: {
                            src:
                              "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                            alt: "user",
                            width: "50"
                          }
                        }),
                        _c("div", { staticClass: "media-body ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between mb-1"
                            },
                            [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Jason Doe")
                              ]),
                              _c(
                                "small",
                                { staticClass: "small font-weight-bold" },
                                [_vm._v("25 Dec")]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            { staticClass: "font-italic mb-0 text-small" },
                            [
                              _vm._v(
                                "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore."
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "list-group-item list-group-item-action list-group-item-light rounded-0",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          staticClass: "rounded-circle",
                          attrs: {
                            src:
                              "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                            alt: "user",
                            width: "50"
                          }
                        }),
                        _c("div", { staticClass: "media-body ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between mb-1"
                            },
                            [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Jason Doe")
                              ]),
                              _c(
                                "small",
                                { staticClass: "small font-weight-bold" },
                                [_vm._v("14 Dec")]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-italic text-muted mb-0 text-small"
                            },
                            [
                              _vm._v(
                                "Lorem ipsum dolor sit amet, consectetur. incididunt ut labore."
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "list-group-item list-group-item-action list-group-item-light rounded-0",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          staticClass: "rounded-circle",
                          attrs: {
                            src:
                              "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                            alt: "user",
                            width: "50"
                          }
                        }),
                        _c("div", { staticClass: "media-body ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between mb-1"
                            },
                            [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Jason Doe")
                              ]),
                              _c(
                                "small",
                                { staticClass: "small font-weight-bold" },
                                [_vm._v("9 Nov")]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-italic text-muted mb-0 text-small"
                            },
                            [
                              _vm._v(
                                "consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore."
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "list-group-item list-group-item-action list-group-item-light rounded-0",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          staticClass: "rounded-circle",
                          attrs: {
                            src:
                              "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                            alt: "user",
                            width: "50"
                          }
                        }),
                        _c("div", { staticClass: "media-body ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between mb-1"
                            },
                            [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Jason Doe")
                              ]),
                              _c(
                                "small",
                                { staticClass: "small font-weight-bold" },
                                [_vm._v("18 Oct")]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-italic text-muted mb-0 text-small"
                            },
                            [
                              _vm._v(
                                "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore."
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "list-group-item list-group-item-action list-group-item-light rounded-0",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          staticClass: "rounded-circle",
                          attrs: {
                            src:
                              "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                            alt: "user",
                            width: "50"
                          }
                        }),
                        _c("div", { staticClass: "media-body ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between mb-1"
                            },
                            [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Jason Doe")
                              ]),
                              _c(
                                "small",
                                { staticClass: "small font-weight-bold" },
                                [_vm._v("17 Oct")]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-italic text-muted mb-0 text-small"
                            },
                            [
                              _vm._v(
                                "consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore."
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "list-group-item list-group-item-action list-group-item-light rounded-0",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          staticClass: "rounded-circle",
                          attrs: {
                            src:
                              "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                            alt: "user",
                            width: "50"
                          }
                        }),
                        _c("div", { staticClass: "media-body ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between mb-1"
                            },
                            [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Jason Doe")
                              ]),
                              _c(
                                "small",
                                { staticClass: "small font-weight-bold" },
                                [_vm._v("2 Sep")]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-italic text-muted mb-0 text-small"
                            },
                            [
                              _vm._v(
                                "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "list-group-item list-group-item-action list-group-item-light rounded-0",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          staticClass: "rounded-circle",
                          attrs: {
                            src:
                              "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                            alt: "user",
                            width: "50"
                          }
                        }),
                        _c("div", { staticClass: "media-body ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between mb-1"
                            },
                            [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Jason Doe")
                              ]),
                              _c(
                                "small",
                                { staticClass: "small font-weight-bold" },
                                [_vm._v("30 Aug")]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-italic text-muted mb-0 text-small"
                            },
                            [
                              _vm._v(
                                "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore."
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "list-group-item list-group-item-action list-group-item-light rounded-0",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "media" }, [
                        _c("img", {
                          staticClass: "rounded-circle",
                          attrs: {
                            src:
                              "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                            alt: "user",
                            width: "50"
                          }
                        }),
                        _c("div", { staticClass: "media-body ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-between mb-3"
                            },
                            [
                              _c("h6", { staticClass: "mb-0" }, [
                                _vm._v("Jason Doe")
                              ]),
                              _c(
                                "small",
                                { staticClass: "small font-weight-bold" },
                                [_vm._v("21 Aug")]
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-italic text-muted mb-0 text-small"
                            },
                            [
                              _vm._v(
                                "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore."
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "col-7 px-0" }, [
            _c("div", { staticClass: "px-4 py-5 chat-box bg-white" }, [
              _c("div", { staticClass: "media w-60 mb-3" }, [
                _c("img", {
                  staticClass: "rounded-circle",
                  attrs: {
                    src:
                      "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                    alt: "user",
                    width: "50"
                  }
                }),
                _c("div", { staticClass: "media-body ml-3" }, [
                  _c(
                    "div",
                    { staticClass: "bg-light rounded py-2 px-3 mb-2" },
                    [
                      _c("p", { staticClass: "text-small mb-0 text-muted" }, [
                        _vm._v("Test which is a new approach all solutions")
                      ])
                    ]
                  ),
                  _c("p", { staticClass: "small text-muted" }, [
                    _vm._v("12:00 PM | Aug 13")
                  ])
                ])
              ]),
              _c("div", { staticClass: "media w-30 ml-auto mb-3" }, [
                _c("div", { staticClass: "media-body" }, [
                  _c(
                    "div",
                    { staticClass: "bg-primary rounded py-2 px-3 mb-2" },
                    [
                      _c("p", { staticClass: "text-small mb-0 text-white" }, [
                        _vm._v(
                          "Test which is a new approach to have all solutions"
                        )
                      ])
                    ]
                  ),
                  _c("img", {
                    staticClass: "rounded-circle",
                    attrs: {
                      src:
                        "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                      alt: "user",
                      width: "50"
                    }
                  }),
                  _c("p", { staticClass: "small text-muted" }, [
                    _vm._v("12:00 PM | Aug 13")
                  ])
                ])
              ]),
              _c("div", { staticClass: "media w-50 mb-3" }, [
                _c("img", {
                  staticClass: "rounded-circle",
                  attrs: {
                    src:
                      "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                    alt: "user",
                    width: "50"
                  }
                }),
                _c("div", { staticClass: "media-body ml-3" }, [
                  _c(
                    "div",
                    { staticClass: "bg-light rounded py-2 px-3 mb-2" },
                    [
                      _c("p", { staticClass: "text-small mb-0 text-muted" }, [
                        _vm._v("Test, which is a new approach to have")
                      ])
                    ]
                  ),
                  _c("p", { staticClass: "small text-muted" }, [
                    _vm._v("12:00 PM | Aug 13")
                  ])
                ])
              ]),
              _c("div", { staticClass: "media w-50 ml-auto mb-3" }, [
                _c("div", { staticClass: "media-body" }, [
                  _c(
                    "div",
                    { staticClass: "bg-primary rounded py-2 px-3 mb-2" },
                    [
                      _c("p", { staticClass: "text-small mb-0 text-white" }, [
                        _vm._v("Apollo University, Delhi, India Test")
                      ])
                    ]
                  ),
                  _c("p", { staticClass: "small text-muted" }, [
                    _vm._v("12:00 PM | Aug 13")
                  ])
                ])
              ]),
              _c("div", { staticClass: "media w-50 mb-3" }, [
                _c("img", {
                  staticClass: "rounded-circle",
                  attrs: {
                    src:
                      "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg",
                    alt: "user",
                    width: "50"
                  }
                }),
                _c("div", { staticClass: "media-body ml-3" }, [
                  _c(
                    "div",
                    { staticClass: "bg-light rounded py-2 px-3 mb-2" },
                    [
                      _c("p", { staticClass: "text-small mb-0 text-muted" }, [
                        _vm._v("Test, which is a new approach")
                      ])
                    ]
                  ),
                  _c("p", { staticClass: "small text-muted" }, [
                    _vm._v("12:00 PM | Aug 13")
                  ])
                ])
              ]),
              _c("div", { staticClass: "media w-50 ml-auto mb-3" }, [
                _c("div", { staticClass: "media-body" }, [
                  _c(
                    "div",
                    { staticClass: "bg-primary rounded py-2 px-3 mb-2" },
                    [
                      _c("p", { staticClass: "text-small mb-0 text-white" }, [
                        _vm._v("Apollo University, Delhi, India Test")
                      ])
                    ]
                  ),
                  _c("p", { staticClass: "small text-muted" }, [
                    _vm._v("12:00 PM | Aug 13")
                  ])
                ])
              ])
            ]),
            _c("form", { staticClass: "bg-light", attrs: { action: "#" } }, [
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  staticClass: "form-control rounded-0 border-0 py-4 bg-light",
                  attrs: {
                    type: "text",
                    placeholder: "Type a message",
                    "aria-describedby": "button-addon2"
                  }
                }),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link",
                      attrs: { id: "button-addon2", type: "submit" }
                    },
                    [_c("i", { staticClass: "fa fa-paper-plane" })]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }